import { Storage } from "@aws-amplify/storage";
export class KarteS3Controller {
    private nodeId = "";
    private karteId = "";
    private imageMaxNum;

    constructor(nodeId: string, karteId: string, imageMaxNum?: number) {
        this.nodeId = nodeId;
        this.karteId = karteId;
        this.imageMaxNum = imageMaxNum ?? 1;
    }

    static arrayBufferToFile = (name: string, buffer: ArrayBuffer): File => {
        const blob: File = Object.assign(
            new Blob([buffer], {
                type: "image/png",
            }),
            {
                lastModified: 0,
                name,
                webkitRelativePath: "",
            },
        );
        return blob;
    };

    static fetchFile = async (url: string, name: string): Promise<File> => {
        const file = await fetch(url).then(async (r) => {
            const f = await r
                .arrayBuffer()
                .then((results) =>
                    KarteS3Controller.arrayBufferToFile(name, results),
                );
            return f;
        });
        return file;
    };

    async getKarteImages(): Promise<File[]> {
        const getS3Results = Storage.list(
            `${this.nodeId}/karte/${this.karteId}`,
            {
                level: "public",
            },
        )
            .then(async (results) => {
                const s3Files = await Promise.all(
                    results.slice(0, this.imageMaxNum).map(async (res) => {
                        const url = await Storage.get(res.key ?? "", {
                            level: "public",
                        });
                        const file = await KarteS3Controller.fetchFile(
                            url,
                            res.key ?? "",
                        );
                        return file;
                    }),
                );
                return s3Files;
            })
            .catch((error: unknown) => {
                console.error(error);
                return [];
            });
        return getS3Results;
    }

    async putKarteImages(images: File[]): Promise<void> {
        await Promise.all(
            images.map((image) =>
                Storage.put(
                    `${this.nodeId}/karte/${this.karteId}/${image.name}`,
                    image,
                    {
                        level: "public",
                    },
                ).catch((error) => {
                    console.error(error);
                }),
            ),
        );
    }

    static async removeKarteImages(images: File[]): Promise<void> {
        await Promise.all(
            images.map(async (image: File) =>
                Storage.remove(image.name).catch((error) => {
                    console.error(error);
                }),
            ),
        );
    }
}
